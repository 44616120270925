import holidays from "date-holidays";
import { isSameDay } from "date-fns";
export const calculateSaldo = (hours: any) => {
  let dailyHours: any = [];
  let balance = 0;
  let usedSaldo = 0;
  const hd = new holidays("FI");
  const WORKING_HOURS = 7.5;
  hours.forEach((item: any) => {
    const spentDate = new Date(item.spent_date);
    const currentHolidays = hd.getHolidays(spentDate);

    const publicHoliday = currentHolidays.find((item) => {
      return isSameDay(new Date(item.date), spentDate);
    });

    //Concatenate multiple daily entries

    const entry = dailyHours.findIndex(
      (day: any) => day.date === Date.parse(item.spent_date)
    );
    //Handle case of weekends and public holidays (they do not accumulate neither decrease saldo)
    //This has to be handled according to weekly hours most likely

    if (spentDate.getDay() === 0 || spentDate.getDay() === 6 || publicHoliday) {
      console.info(
        spentDate.toISOString() +
          " You worked on weekend or public holiday! This is not yet handled, hours: " +
          item.hours
      );
    } else {
      //Calculate total used saldo for sake of simplicity
      if (
        item.task.name === "Saldo holiday" ||
        item.task.name === "Liukumasaldovapaa"
      )
        usedSaldo = usedSaldo + item.hours;

      //First entry for a day, add it to array
      if (entry === -1)
        dailyHours.push({
          hours: item.hours,
          date: Date.parse(item.spent_date),
          task: item.task.name,
        });
      //otherwise just add the hours up
      else dailyHours[entry].hours = dailyHours[entry].hours + item.hours;
    }
  });
  //calculate balance totals
  dailyHours.forEach((item: any) => {
    balance = balance + item.hours - WORKING_HOURS;
  });

  //sort chronologically
  dailyHours.sort((a: any, b: any) => a.date - b.date);

  //Wrangle data for chart
  let history: any = [];
  let prevSaldo = 0;
  dailyHours.forEach((item: any) => {
    if (item.task === "Saldo holiday" || item.task === "Liukumasaldovapaa") {
      prevSaldo = prevSaldo - WORKING_HOURS;
    } else prevSaldo = prevSaldo + item.hours - WORKING_HOURS;
    history.push({ x: new Date(item.date), y: prevSaldo });
  });
  return { hours: balance - usedSaldo, dailyData: history };
};
