import { useState, useEffect } from "react";
import qs from "query-string";
import { getHours, getUser } from "../api/HarvestApi";
import { useLocation } from "wouter";
import { calculateSaldo } from "../utils/calculateSaldo";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";

import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(annotationPlugin);

export const options: any = {
  plugins: {
    autocolors: false,
    annotation: {
      drawTime: "afterDatasetsDraw",
      annotations: [
        {
          drawTime: "afterDraw",
          id: "hourlimit-line",
          type: "line",
          mode: "horizontal",
          yMin: 40,
          yMax: 40,
          borderColor: "red",
          borderWidth: 2,
        },
      ],
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
      },
    },
  },
};

export const BalancePage = () => {
  let search = qs.parse(window.location.search);

  const [hours, setHours] = useState(0);
  const [location, setLocation] = useLocation();
  const [history, setHistory] = useState({
    datasets: [
      {
        label: "Saldo history",
        data: {},
      },
    ],
  });
  useEffect(() => {
    const harvestHours = async () => {
      try {
        const userData = await getUser(
          "https://api.harvestapp.com/v2/users/me"
        );
        const hours: any = await getHours(
          "https://api.harvestapp.com/v2/time_entries?user_id=" + userData.id
        );
        const saldoTotal = calculateSaldo(hours);
        setHours(saldoTotal.hours);
        setHistory({
          datasets: [{ label: "Saldo history", data: saldoTotal.dailyData }],
        });
      } catch (err) {
        setLocation("/");
      }
    };
    if (search.access_token) {
      localStorage.setItem("harvestKey", search.access_token.toString());
      setLocation("/balance");
    }
    harvestHours();
  }, [setLocation, search.access_token, setHours, setHistory]);
  return (
    <>
      <h1>
        Your current hour balance is{" "}
        {hours > 40 ? (
          <span style={{ color: "red" }}>{parseFloat(hours.toFixed(2))}</span>
        ) : (
          <span style={{ color: "green" }}>{parseFloat(hours.toFixed(2))}</span>
        )}
      </h1>
      <Line options={options} data={history} />
    </>
  );
};
