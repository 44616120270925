import { Route } from "wouter";
import { BalancePage } from "./components/BalancePage";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Route path="/">
        <a href={`https://id.getharvest.com/oauth2/authorize?client_id=${process.env.REACT_APP_HARVEST_CLIENTID}&response_type=token`}>
          Login
        </a>
      </Route>
      <Route path="/balance" component={BalancePage} />
    </div>
  );
}

export default App;
