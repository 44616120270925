export const getHours = async (url: string): Promise<any> => {
  let ret: any = [];
  try {
    const resp = await fetch(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("harvestKey"),
        "Harvest-Account-Id": "1493573",
        "User-Agent": "HarvestBalance-DEV (timo.kaipiainen@upsert.fi)",
      },
    });
    if (resp.status === 401) throw new Error();
    const data = await resp.json();
    ret.push(...data.time_entries);
    if (data.links.next !== null) {
      return ret.concat(await getHours(data.links.next));
    } else return ret;
  } catch (error) {
    console.error(error);
  }
};
export const getUser = async (url: string): Promise<any> => {
  try {
    const resp = await fetch(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("harvestKey"),
        "Harvest-Account-Id": "1493573",
        "User-Agent": "HarvestBalance-DEV (timo.kaipiainen@upsert.fi)",
      },
    });
    if (resp.status === 401) throw new Error();
    const data = await resp.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
